.container{
    text-align: center;
}

.small{
    font-size: 70%;
}

.card {
    width: 18rem;
}