.imageApple{
    height: 2em
}

.imageGoogle{
    height: 3em
}

.container{
    text-align: center;
}

.small{
    font-size: 70%;
}