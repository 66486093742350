.card {
    white-space: normal;
    width: 345px;
    height: 200px;
    position: relative;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
}

.cardLogo {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 50px;
    height: 50px;
}

.cardImage {
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
}

.cardName {
    position: absolute;
    right: 10px;
    font-size: 15px;
}

.cardIssuer {
    font-size: 13px;
    position: absolute;
    left: 10px;
    bottom: 10px;
}